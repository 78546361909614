import componentKeys from "@/helpers/wc/componentKeys";
import defaultPosition from '@/helpers/wc/positionSettings';

export const buttonTypesRegister = {
  redirect: {
    title: "Redirect",
    value: 1
  },
  submitVisitor: {
    title: "Submit Visitor",
    value: 2
  },
  submitEmployeeSearch: {
    title: "Submit Employee Search",
    value: 3
  },
  submitDropinForm: {
    title: "Submit Dropin Visitor",
    value: 4
  },
  cleanEmployeeSearch: {
    title: "Clean Employee Search Input",
    value: 5
  },
  dropin: {
    title: "Dropin Button",
    value: 6
  },
  addAttendant: {
    title: "Add Visitor",
    value: 7
  },
  visitExpress: {
    title: "Visit Express",
    value: 8
  },
  submitQueueRegister: {
    title: "Submit Queue",
    value: 9
  },
  clearInput: {
    title: "Clear Input",
    value: 10
  },
  visitorSendSelfSms: {
    title: "Send Sms To Visitor",
    value: 11
  },
  storeJobDepartment: {
    title: "Job Department Button",
    value: 12
  },
  sendNotificationToEmployee: {
    title: "Send Message to Employee",
    value: 13
  },
  shiftDownButton: {
    title: "Shift Elements Down",
    value: 14
  },
  externalRedirect: {
    title: "External Redirect",
    value: 15
  },
  finishRegister: {
    title: "Finish Registration",
    value: 16
  },
  storeInfoSearchCategory: {
    title: "InfoSearch Category",
    value: 17
  },
  parkingRegister: {
    title: "Parking",
    value: 18
  },
  taxiOrder: {
    title: "Order Taxi",
    value: 19
  },
  graveyardSearch: {
    title: "Graveyard Search",
    value: 20
  },
  searchNext: {
    title: "Search Next",
    value: 21
  },
  submitPerson: {
    title: "Submit Person",
    value: 22
  },
  selectQueue: {
    title: "Select Queue",
    value: 23
  },
  pagePrint: {
    title: "Page Print",
    value: 24
  },
  blockPage: {
    title: "Block Page",
    value: 25
  },
  gravarGraveyardSearch: {
    title: "Gravar Graveyard Search",
    value: 26
  },
  sendSiteLink: {
    title: "Send the site link",
    value: 27
  },
  reciever: {
    title: "Reciever Department",
    value: 28
  }
};

export const button = {
  title: 'Button',
  isNamedContent: false,
  [componentKeys.name]: 'buttonBase',
  [componentKeys.text]: [],

  [componentKeys.styles]: {
    ...defaultPosition,
    [componentKeys.fontSize]: '12px',
    [componentKeys.fontWeight]: 400,
    [componentKeys.color]: "#000",
    [componentKeys.borderWidth]: '1px',
    [componentKeys.borderStyle]: 'solid',
    [componentKeys.borderColor]: "#000",
    [componentKeys.bgColor]: 'unset',
    [componentKeys.borderRadius]: '5px',
    [componentKeys.fontFamily]: "sans-serif",
    [componentKeys.opacity]: 1,
  },

  [componentKeys.properties]: {
    [componentKeys.routeName]: "",
    [componentKeys.externalReturnUrl]: "",
    [componentKeys.openInNewWindow]: false,
    [componentKeys.openInNewTab]: false,
    [componentKeys.saveStatistics]: true,
    [componentKeys.saveStatisticsAs]: "",
    [componentKeys.type]: buttonTypesRegister.redirect.value,
    [componentKeys.inputId]: "",
    [componentKeys.startHour]: "00:00",
    [componentKeys.endHour]: "00:00",
    [componentKeys.checkDays]: false,
    [componentKeys.days]: [],
    [componentKeys.departmentId]: '',
    [componentKeys.outWorkHoursRouteName]: '',
    [componentKeys.departmentSentSms]: true,
    [componentKeys.departmentSentEmail]: true,
    [componentKeys.departmentSentToVisitor]: false,
    [componentKeys.jobDepartment]: {},
    [componentKeys.sendMessageToDropinVisitor]: false,
    [componentKeys.employeeSendEmail]: true,
    [componentKeys.visitorSendNotification]: true,
    [componentKeys.employeeSendSMS]: false,
    [componentKeys.printLabel]: false,
    [componentKeys.messageToVisitor]: [],
    [componentKeys.sendNotificationToEmployee]: false,
    [componentKeys.customMessageEmployee]: false,
    [componentKeys.customEmployeeMessageContent]: [],
    [componentKeys.customDropinFormMessage]: false,
    [componentKeys.customDropinFormMessageContent]: [],
    [componentKeys.labelTemplate]: 1, // default label template
    [componentKeys.redirectToRegister]: false,
    [componentKeys.parkingAreaNo]: '',
    [componentKeys.parkUserName]: '',
    [componentKeys.city]: 'Oslo',
    [componentKeys.postal]: '',
    [componentKeys.streetName]: '',
    [componentKeys.streetNumber]: '',
    [componentKeys.streetLetter]: '',
    [componentKeys.latitude]: '',
    [componentKeys.longitude]: '',
    [componentKeys.selectDropinByOrder]: false,
    [componentKeys.selectDropinByName]: false,
    [componentKeys.dropinName]: '',
    [componentKeys.dropinOrder]: '',
    siteLinkId: '',
    employee: '',
    isOneTime: true,
    hidden: false,
    queueId: 0,
    printImmediately: false
  },
};
